<template lang="pug">
  div(ref="swiper" class="swiper__entry swiper")
    div.swiper-wrapper
      div(v-for="(item, index) in functionalityItems" :key="index").swiper-slide
        div.functionality__slide
          div.functionality__image
            img(:src="item.imageSrc" :alt="item.subtitle")
          div.functionality__content
            h2.h2.functionality__header {{ item.title }}
            h3.h3.functionality__title {{ item.subtitle }}
            div.functionality__text
              p {{ item.description }}

    div.functionality__pagination
      div.swiper-button-prev
      div.swiper-pagination.pagination__relative.slider__pagination
      div.swiper-button-next
</template>

<script>
// images
import Image1 from '@/assets/images/app-1.png'
import Image2 from '@/assets/images/app-2.png'
import Image3 from '@/assets/images/app-3.png'
import Image4 from '@/assets/images/app-4.png'
import Image5 from '@/assets/images/app-5.png'
import Image6 from '@/assets/images/app-6.png'
import Image7 from '@/assets/images/app-7.png'
// swiper
import Swiper, { Navigation, EffectFade, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'
export default {
  name: 'FunctionalitySlider',
  data () {
    return {
      functionalityItems: [
        {
          imageSrc: Image1,
          title: 'App functionality',
          subtitle: 'Conversation',
          description: 'This functionality empowers you to engage in conversations across diverse languages, with our system automatically translating messages in real-time. It\'s as simple as selecting the languages spoken by the participants, initiating a chat, and relishing natural and unhindered communication, breaking down the barriers of language.'
        },
        {
          imageSrc: Image2,
          title: 'App functionality',
          subtitle: 'Text translation',
          description: 'You can input text in one language and receive an accurate translation into their desired language. Translation can apply to both short phrases and sentences as well as longer texts. Our algorithm leverages advanced natural language processing technologies to ensure the accuracy and efficiency of the translation process.'
        },
        {
          imageSrc: Image3,
          title: 'App functionality',
          subtitle: 'Voice translation',
          description: 'Speak a phrase, and we will instantly translate it into your chosen language. Just press the record button, and within seconds, you\'ll receive the accurate translation you need! This feature makes communication across languages effortless and efficient, whether you\'re traveling, conducting business internationally, or simply connecting with friends from different parts of the world.'
        },
        {
          imageSrc: Image4,
          title: 'App functionality',
          subtitle: 'Camera translation',
          description: 'Take a photo of the text in another language, and we will provide you with a real-time translation. This feature is incredibly convenient when traveling or when you need to understand documents, signs, or any printed material in unfamiliar languages. It simplifies the process of gaining information and ensures you can navigate effectively in diverse linguistic environments.'
        },
        {
          imageSrc: Image5,
          title: 'App functionality',
          subtitle: 'Translation of objects',
          description: 'Are you trying to identify or understand something specific? Simply aim your camera at it, and we\'ll translate any pertinent text or inscriptions. This functionality proves invaluable when you\'re exploring unfamiliar locations, decoding foreign menus, or comprehending product labels in languages you might not know well.'
        },
        {
          imageSrc: Image6,
          title: 'App functionality',
          subtitle: 'Phrases book',
          description: 'The "Phrasebook" feature will expand your communication capabilities in different languages. In the "Phrasebook," you will find essential phrases for everyday conversation, adapted for various languages, making it easier for you to navigate different linguistic situations.'
        },
        {
          imageSrc: Image7,
          title: 'App functionality',
          subtitle: 'Offline mode',
          description: '"Offline" mode boosts mobile translator flexibility and accessibility in areas with unreliable internet. Use it in remote places, during international travel, with poor cell coverage, or to save on costly roaming. It\'s a crucial tool, guaranteeing access to vital translations no matter where you are or your internet status.'
        }
      ]
    }
  },
  mounted () {
    new Swiper(this.$refs.swiper, {
      modules: [Navigation, Pagination, EffectFade],
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    })
  }
}
</script>
